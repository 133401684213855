<template>
  <div>
    <div id="arrivals-page">
      <h1 class="h1-title">Pending Arrivals</h1>
      <search-bar class="margin-right margin-top-2" availableFilterMode="pending_arrivals"></search-bar>
      <filter-bar />
      <div class="results-table-container">
        <results-table class="margin-top margin-right" availableSearchMode="pending_arrivals" ref="resultsTable" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { endpoints } from "../../api/axiosHandler.js";

import ResultsTable from "../../components/Views/v2/ResultsTable.vue";
import SearchBar from "../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../components/Views/v2/FilterBar.vue";

export default {
  name: "PendingArrivals",
  components: { ResultsTable, SearchBar, FilterBar },
  methods: {
    ...mapActions({
      getForms: "formStore/getForms",
      setFormsUrl: "formStore/setCurrentUrl"
    })
  },
  mounted: function () {
    let app = this;
    this.setFormsUrl(endpoints.form + "pending-arrivals");
    this.$nextTick(() => {
      app.getForms({});
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#arrivals-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
